import { useObjectVal } from "react-firebase-hooks/database";
import firebase from "firebase";

import { SalaDados } from "../tipos";

interface ListSalaDados {
  [id: string]: SalaDados;
}
export interface ListMateriasDados {
  [id: string]: string;
}

/**
 * Retorna a lista de salas armazenada no servidor.
 */
export function useSalas(): [ListSalaDados | undefined, boolean] {
  const ref = firebase.database().ref("salas");
  const [snapshot, loading] = useObjectVal<ListSalaDados>(ref);

  return [snapshot, loading];
}

/**
 * Retorna a lista de salas armazenada no servidor.
 */
export function useMaterias(): [ListMateriasDados | undefined, boolean] {
  const ref = firebase.database().ref("materias");
  const [snapshot, loading] = useObjectVal<ListMateriasDados>(ref);

  return [snapshot, loading];
}

/**
 * Retorna a lista de salas armazenada no servidor.
 */
export function useMateria(id: string): [string, boolean] {
  const [materias, loading] = useMaterias();

  let nome = "";

  if (materias && materias[id]) {
    nome = materias[id];
  }

  return [nome, loading];
}

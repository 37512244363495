import React from "react";
import Navbar from "./components/Navbar";

import {
  ThemeProvider,
  Divider,
  Box,
  Typography,
  Container,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { BrowserRouter as Router } from "react-router-dom";
import { theme } from "./common/themer";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import brLocal from "date-fns/locale/pt-BR";
import RouterInjector from "./views/Router";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase";
import Login from "./views/Login/Login";

function App() {
  const [user] = useAuthState(firebase.auth());

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocal}>
      <Router>
        <ThemeProvider theme={theme}>
          <Navbar>
            <Box maxWidth="100vw">
              {user ? (
                <RouterInjector />
              ) : (
                <Box mt={3}>
                  <Container maxWidth="sm">
                    <Login />
                  </Container>
                </Box>
              )}
            </Box>
            <Box mt={4}>
              <Divider />
            </Box>
            <Box textAlign="center" color="rgba(0,0,0,0.4)" p={2}>
              <Typography variant="body2">
                🛠 Desenvolvido por Kayky de Brito - 2°A
              </Typography>
              <Typography variant="body2">hello@kaykyb.com</Typography>
            </Box>
          </Navbar>
        </ThemeProvider>
      </Router>
    </MuiPickersUtilsProvider>
  );
}

export default App;

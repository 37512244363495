import React, { useState } from "react";
import { Tabs, Tab, Divider, Container } from "@material-ui/core";
import EventIcon from "@material-ui/icons/MailOutline";
import SubIcon from "@material-ui/icons/SubscriptionsOutlined";
import ClassIcon from "@material-ui/icons/ClassOutlined";

import { TabPanel } from "../components/TabPanel";
import Mural from "./Mural/Mural";
import Aulas from "./Aulas/Aulas";

interface SalaProps {
  sala: string;
}

export default function Sala({ sala }: SalaProps) {
  const [page, setPage] = useState(0);

  const handleChange = (e: React.ChangeEvent<{}>, newValue: number) => {
    setPage(newValue);
  };

  return (
    <>
      <Container maxWidth="md" style={{ padding: 0 }}>
        <Tabs
          value={page}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon label tabs example"
          scrollButtons="auto"
          variant="fullWidth"
        >
          <Tab icon={<EventIcon />} label="E-mail" />
          <Tab icon={<SubIcon />} label="Aulas" />
          <Tab icon={<ClassIcon />} label="Lições" />
          <Tab icon={<ClassIcon />} label="Chamada" />
        </Tabs>
      </Container>
      <Divider />
      <TabPanel value={page} index={0}>
        <Container maxWidth="sm" style={{ padding: 0 }}>
          <Mural sala={sala} />
        </Container>
      </TabPanel>
      <TabPanel value={page} index={1}>
        <Aulas sala={sala} />
      </TabPanel>
      <TabPanel value={page} index={2}>
        Em breve suas atividades poderão ser consultadas aqui.
      </TabPanel>
    </>
  );
}

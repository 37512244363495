import React from "react";
import {
  Grid,
  Typography,
  Divider,
  Box,
  CircularProgress,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@material-ui/core";
import firebase from "firebase";
import { getNomeClasse } from "../../common/util";
import { Mural } from "../../common/atividade";
import createDOMPurify from "dompurify";
import FolderIcon from "@material-ui/icons/FileCopyOutlined";
import { useObjectVal } from "react-firebase-hooks/database";

const DOMPurify = createDOMPurify(window);

interface MuralPostProps {
  sala: string;
  id: string;
}

function BoxDado(dado: React.PropsWithChildren<{}>) {
  return (
    <Box pt={1} pb={1}>
      <Typography color="textSecondary" variant="body2">
        {dado.children}
      </Typography>
    </Box>
  );
}

export default function PostMural(props: MuralPostProps) {
  const ref = firebase.database().ref(`atividades/${props.sala}/${props.id}`);
  const [value, loading] = useObjectVal<Mural>(ref);

  const data = value ? new Date(value.data) : null;
  const dia = data ? data.toLocaleDateString().replace("/2020", "") : "";
  const horas = data ? data.toLocaleTimeString().split(":") : "";

  return (
    <div>
      {loading && (
        <Box
          component="div"
          mt={3}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <CircularProgress />
        </Box>
      )}
      {value && (
        <>
          <div style={{ paddingBottom: "24px" }}>
            <Typography variant="h6">{getNomeClasse(props.sala)}</Typography>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography gutterBottom variant="h5">
                  {value.assunto}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Divider />
          <BoxDado>
            <strong>Professor:</strong> {value.profNome || "?"}
          </BoxDado>
          <Divider />
          <BoxDado>
            <strong>E-mail:</strong> {value.prof}
          </BoxDado>
          <Divider />
          <BoxDado>
            <strong>Matéria:</strong> ?
          </BoxDado>
          <Divider />
          <BoxDado>
            <strong>Tarefa enviada:</strong>{" "}
            {dia + " as " + horas[0] + ":" + horas[1]}
          </BoxDado>
          <Divider />
          <BoxDado>
            <strong>Prazo:</strong> Verifique com o Professor
          </BoxDado>

          <Divider />

          <Box mt={2} mb={2}>
            <Typography variant="body2">
              <strong>Atividade:</strong>
            </Typography>

            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(value.corpo),
              }}
            />
          </Box>

          <Divider />

          <Box mt={2}>
            <Typography variant="body2">
              <strong>Anexos:</strong>
              {!value.anexos || value.anexos.length === 0 ? (
                <> Nenhum</>
              ) : (
                <List>
                  {value.anexos.map((f, i) => (
                    <a href={f.url}>
                      <ListItem key={i} button>
                        <ListItemAvatar>
                          <Avatar>
                            <FolderIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={f.nome} />
                      </ListItem>
                    </a>
                  ))}
                </List>
              )}
            </Typography>
          </Box>

          <a href={"mailto:" + value.prof}>
            <Box mt={2}>
              <Button variant="contained" color="primary" fullWidth>
                Responder por E-mail
              </Button>
            </Box>
          </a>
        </>
      )}
    </div>
  );
}

import React from "react";
import {
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";
import { useSalas } from "../../common/hooks/salas";
import firebase from "firebase";
import { useAlunos } from "../../common/hooks/alunos";

export default function LoginAluno() {
  const [salas] = useSalas();
  const [alunos] = useAlunos();

  const [state, setState] = React.useState<{
    serie: string;
    numero: string;
    dia: number;
    mes: number;
    ano: number;
    name: string;
    erro: boolean;
    logando: boolean;
  }>({
    serie: "",
    dia: 1,
    mes: 1,
    ano: 2000,
    numero: "",
    erro: false,
    name: "hai",
    logando: false,
  });

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as keyof typeof state;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleLoginClick = async () => {
    setState({
      ...state,
      logando: true,
    });

    const _dia = state.dia < 10 ? "0" + state.dia : state.dia.toString();
    const _mes = state.mes < 10 ? "0" + state.mes : state.mes.toString();

    const res = await firebase
      .auth()
      .signInWithEmailAndPassword(state.numero, _dia + _mes + state.ano);

    setState({
      ...state,
      erro: !res,
      logando: false,
    });
  };

  const menuSalaItem = (idSala: string, i: number) => {
    const sala = salas![idSala];

    return (
      <MenuItem value={idSala} key={i}>
        {sala.nome}
      </MenuItem>
    );
  };

  const menuAlunoItem = (idAluno: string, i: number) => {
    if (!alunos || !salas) {
      return (
        <MenuItem value="" key={i}>
          ""
        </MenuItem>
      );
    }

    const al = alunos[salas[state.serie].alunos[idAluno].id];

    return (
      <MenuItem value={al.email} key={i}>
        {al.nome}
      </MenuItem>
    );
  };

  const days = [];

  for (let i = 1; i < 32; i++) {
    days.push(i);
  }

  const months = [];

  for (let i = 1; i < 13; i++) {
    months.push(i);
  }

  const years = [];

  for (let i = 1995; i < 2020; i++) {
    years.push(i);
  }

  return (
    <Box>
      <Typography align="center">
        {!(state.serie && state.numero)
          ? "Quem é você?"
          : "Quando você nasceu?"}
      </Typography>

      {!salas && (
        <Box mt={2} width="100%" textAlign="center">
          <CircularProgress />
        </Box>
      )}
      {salas && (
        <>
          {!(state.serie && state.numero) ? (
            <>
              <Box mt={2} width="100%">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Sala
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={state.serie}
                    onChange={handleChange}
                    label="Sala"
                    inputProps={{
                      name: "serie",
                      id: "filled-age-native-simple",
                    }}
                    fullWidth
                  >
                    {Object.keys(salas).map((x, i) => menuSalaItem(x, i + 1))}
                  </Select>
                </FormControl>
              </Box>

              {state.serie && (
                <Box mt={2} width="100%">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Nome
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={state.numero}
                      onChange={handleChange}
                      label="Nome"
                      inputProps={{
                        name: "numero",
                        id: "filled-age-native-simple",
                      }}
                      fullWidth
                    >
                      {Object.keys(salas[state.serie].alunos).map((x, i) =>
                        menuAlunoItem(x, i + 1)
                      )}
                    </Select>
                  </FormControl>
                </Box>
              )}
            </>
          ) : (
            <>
              <Box mt={2} width="100%">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Dia
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={state.dia}
                    onChange={handleChange}
                    label="Dia"
                    inputProps={{
                      name: "dia",
                      id: "filled-age-native-simple",
                    }}
                    fullWidth
                  >
                    {days.map((x) => (
                      <MenuItem value={x} key={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box mt={2} width="100%">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Mês
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={state.mes}
                    onChange={handleChange}
                    label="Mês"
                    inputProps={{
                      name: "mes",
                      id: "filled-age-native-simple",
                    }}
                    fullWidth
                  >
                    {months.map((x) => (
                      <MenuItem value={x} key={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box mt={2} width="100%">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Ano
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={state.ano}
                    onChange={handleChange}
                    label="Ano"
                    inputProps={{
                      name: "ano",
                      id: "filled-age-native-simple",
                    }}
                    fullWidth
                  >
                    {years.map((x) => (
                      <MenuItem value={x} key={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {state.erro && (
                <Box mt={2} width="100%">
                  <Alert severity="error">Data de nascimento incorreta.</Alert>
                </Box>
              )}

              {!state.logando ? (
                <Box mt={2} width="100%">
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={handleLoginClick}
                  >
                    entrar
                  </Button>
                </Box>
              ) : (
                <Box mt={2} width="100%" textAlign="center">
                  <CircularProgress />
                </Box>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
}

import React from "react";
import Room from "../components/Room";
import { Link } from "react-router-dom";

export default function RoomSelection() {
  return (
    <div>
      <Link to="sala/2anoA">
        <Room />
      </Link>
    </div>
  );
}

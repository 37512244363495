import React, { useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Container,
  Paper,
  Button,
  CircularProgress,
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import CriarAulaDialog from "./CriarAulaDialog";
import { Aula as AulaSchema } from "../../common/atividade";
import firebase from "firebase";
import { Link } from "react-router-dom";
import AtividadeIcon from "@material-ui/icons/NotificationImportant";
import { useObjectVal } from "react-firebase-hooks/database";
import { useIsProfessor } from "../../common/hooks/alunos";
import { getParameterByName } from "../../common/util";
import { useMaterias, ListMateriasDados } from "../../common/hooks/salas";

export function Aula(
  props: React.PropsWithChildren<{ sala: string; aula: AulaSchema }>
) {
  const ref = firebase
    .database()
    .ref(`licoes/${props.sala}/${props.aula.atividadeId}/prazo`);

  const [prazoVal] = useObjectVal<string>(ref);

  const prazo = prazoVal
    ? new Date(prazoVal).toLocaleDateString()
    : "Carregando...";

  return (
    <Box mb={1}>
      <Card variant="outlined" elevation={0}>
        <CardActionArea>
          <CardMedia
            component="img"
            alt="Thumbnail"
            height="180"
            image={`https://img.youtube.com/vi/${getParameterByName(
              "v",
              props.aula.link
            )}/0.jpg`}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {props.aula.title}
            </Typography>
            {props.aula.atividadeId && (
              <>
                <Chip
                  color="primary"
                  icon={<AtividadeIcon />}
                  label={`Prazo: ${prazo}`}
                />
              </>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
}

export default function Aulas(props: { sala: string }) {
  const [criarAulaOpen, handleSetOpen] = useState(false);

  const [filtroMateria, setFiltroMateria] = useState("tds");

  const ref = firebase.database().ref(`aulas/${props.sala}`);
  const [aulas_raw] = useObjectVal<{ [id: string]: AulaSchema }>(ref);

  const [isProfessor] = useIsProfessor();

  const [materias_raw] = useMaterias();
  const materias: ListMateriasDados | undefined = materias_raw
    ? { tds: "Todas", ...materias_raw }
    : undefined;

  const materiasIds = Object.keys(materias || {});

  const aulas = aulas_raw;
  const aulaIds = Object.keys(aulas || {});

  const handleMateriaChange = (event: React.ChangeEvent<any>) => {
    setFiltroMateria(event.target.value);
  };

  const handleAdicionarAula = () => {
    handleSetOpen(true);
  };

  return (
    <Box>
      {isProfessor && (
        <Paper square elevation={2} style={{ padding: 0, margin: "0 auto" }}>
          <Container maxWidth="sm" style={{ padding: 0, margin: "0 auto" }}>
            <Box p={3}>
              <strong>Adicionar aula</strong>
              <p>
                Professor, utilize este espaço para adicionar videoaulas do CMSP
                e YouTube, junto de atividades relacionadas.
              </p>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleAdicionarAula}
              >
                Adicionar Aula
              </Button>
            </Box>
          </Container>
        </Paper>
      )}

      <Paper square elevation={1} style={{ padding: 0, margin: "0 auto" }}>
        <Container maxWidth="sm" style={{ padding: 0, margin: "0 auto" }}>
          <Box p={3}>
            <strong>Buscar Aula</strong>
            <Box mt={2}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">
                  Matéria
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Matéria"
                  value={filtroMateria}
                  onChange={handleMateriaChange}
                  defaultValue="tds"
                  fullWidth
                >
                  {materiasIds.map((x, i) => (
                    <MenuItem value={x}>{materias ? materias[x] : ""}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Container>
      </Paper>

      <Box pt={3}>
        <Container maxWidth="sm">
          {aulaIds.length === 0 && (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          )}
          {aulaIds
            .sort((a, b) => aulas![b].data! - aulas![a].data!)
            .map((x, i) => (
              <React.Fragment key={i}>
                {(filtroMateria === "tds" ||
                  aulas![x].materiaId === filtroMateria) && (
                  <Link to={`/sala/${props.sala}/aulas/${x}`}>
                    <Aula sala={props.sala} aula={aulas![x]} key={i} />
                  </Link>
                )}
              </React.Fragment>
            ))}
        </Container>
      </Box>

      <CriarAulaDialog
        sala={props.sala}
        open={criarAulaOpen}
        setOpen={handleSetOpen}
      />
    </Box>
  );
}

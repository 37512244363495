import { useObjectVal } from "react-firebase-hooks/database";
import firebase from "firebase";

import { AlunoDados } from "../tipos";
import { useAuthState } from "react-firebase-hooks/auth";

interface ListAlunoDados {
  [id: string]: AlunoDados;
}

/**
 * Retorna a lista de alunos armazenada no servidor.
 */
export function useAlunos(): [ListAlunoDados | undefined, boolean] {
  const ref = firebase.database().ref("alunos");
  const [snapshot, loading] = useObjectVal<ListAlunoDados>(ref);

  return [snapshot, loading];
}

/**
 * Retorna o nome de um aluno ou professor baseado em seu e-mail
 * @param s O e-mail a procurar
 */
export function useNomeFromEmail(s?: string): [string, boolean] {
  const [alunos, loading] = useAlunos();

  if (!s) {
    return ["", false];
  }

  // É a conta de professor
  if (s === "professor@acetto.kaykyb.com") {
    return ["Professor", false];
  }

  let nome = "";

  if (alunos) {
    for (const key in alunos) {
      if (alunos.hasOwnProperty(key)) {
        const aluno = alunos[key];

        if (aluno.email === s) nome = aluno.nome;
      }
    }
  }

  return [nome, loading];
}

export function useIsProfessor(): [boolean] {
  const [user] = useAuthState(firebase.auth());

  if (user && user.email === "professor@acetto.kaykyb.com") return [true];

  return [false];
}

import React from "react";
import {
  makeStyles,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from "@material-ui/core";

import ano2 from "../assets/rooms/2ano.png";

const useStyles = makeStyles({
  root: {},
  media: {
    height: 140,
  },
});

export default function Room() {
  const classes = useStyles();

  return (
    <Card className={classes.root} elevation={8}>
      <CardActionArea>
        <CardMedia className={classes.media} image={ano2} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            2° Ano A
          </Typography>
          <Typography color="textSecondary" component="p">
            Ensino Médio
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// Inicializa o Firebase
import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDiaZrlaeibl-IyWb0TLJSSkBQCWouFaQg",
  authDomain: "acetto-websys.firebaseapp.com",
  databaseURL: "https://acetto-websys.firebaseio.com",
  projectId: "acetto-websys",
  storageBucket: "acetto-websys.appspot.com",
  messagingSenderId: "555439537227",
  appId: "1:555439537227:web:4ff9e8b65d8cba34e221ce",
};

firebase.initializeApp(firebaseConfig);
window.firebase = firebase;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from "react";
import { useParams, Route, Switch } from "react-router-dom";
import PostMural from "./Mural/PostMural";
import Login from "./Login/Login";
import { Container, Box } from "@material-ui/core";
import Sala from "./Sala";
import RoomSelection from "./RoomSelection";
import Aula from "./Aulas/Aula";

function InjectSala() {
  let { ano } = useParams();

  return <Sala sala={ano} />;
}

function InjectPostMural() {
  let { ano, ativ } = useParams();

  return <PostMural sala={ano} id={ativ} />;
}

function InjectAula() {
  let { ano, ativ } = useParams();

  return <Aula sala={ano} id={ativ} />;
}

export default function RouterInjector() {
  return (
    <Switch>
      <Route path="/" exact>
        <Box mt={3}>
          <Container maxWidth="sm">
            <RoomSelection />
          </Container>
        </Box>
      </Route>
      <Route path="/sala/:ano" exact>
        <InjectSala />
      </Route>
      <Route path="/login" exact>
        <Box mt={3}>
          <Container maxWidth="sm">
            <Login />
          </Container>
        </Box>
      </Route>
      <Route path="/sala/:ano/mural/:ativ" exact>
        <Box mt={3}>
          <Container maxWidth="sm">
            <InjectPostMural />
          </Container>
        </Box>
      </Route>
      <Route path="/sala/:ano/aulas/:ativ" exact>
        <Box mt={0}>
          <InjectAula />
        </Box>
      </Route>
    </Switch>
  );
}

export const getNomeClasse = (id: string) => {
  if (id.endsWith("TEC")) {
    return "1° Ano TEC";
  } else {
    return id[0] + "° Ano A";
  }
};

export const getSimplifiedNomeClasse = (id: string) => {
  if (id.endsWith("TEC")) {
    return "1° T";
  } else {
    return id[0] + "° A";
  }
};

export const getParameterByName = (name: string, url?: string) => {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const hashCode = (str: string) => {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

export const intToRGB = (i: number) => {
  var c = (i & 0x00ffffff).toString(16).toUpperCase();

  return "00000".substring(0, 6 - c.length) + c;
};

import React from "react";
import { useList } from "react-firebase-hooks/database";
import firebase from "firebase";

import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Grid,
  Typography,
  CircularProgress,
  Box,
} from "@material-ui/core";
import AssignmentTwoTone from "@material-ui/icons/AssignmentOutlined";
import { Link } from "react-router-dom";
import { getNomeClasse } from "../../common/util";

interface MuralProps {
  sala: string;
}

export default function Mural({ sala }: MuralProps) {
  const ref = firebase.database().ref(`atividades/${sala}`);
  const [values, loading] = useList(ref);

  return (
    <Box p={3}>
      <div style={{ paddingBottom: "24px" }}>
        <Typography variant="h6">{getNomeClasse(sala)}</Typography>
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography gutterBottom variant="h5">
              E-mail da Sala
            </Typography>
          </Grid>
        </Grid>
        <Typography color="textSecondary" variant="body2">
          Abaixo estão os e-mails enviados pelo seus professores.
        </Typography>
      </div>
      <Divider />

      {loading && (
        <Box
          component="div"
          mt={3}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <CircularProgress />
        </Box>
      )}

      {(values || [])
        .sort((a, b) => Date.parse(b.val().data) - Date.parse(a.val().data))
        .map((x, i) => {
          const v = x.val();

          const data = new Date(v.data);
          const dia = data.toLocaleDateString().replace("/2020", "");
          const horas = data.toLocaleTimeString().split(":");

          return (
            <List>
              <Link to={`/sala/${sala}/mural/${x.key}`}>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar>
                      <AssignmentTwoTone />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={v.assunto}
                    secondary={dia + " - " + horas[0] + ":" + horas[1]}
                  />
                </ListItem>
              </Link>
              <Divider variant="inset" component="li" />
            </List>
          );
        })}
    </Box>
  );
}

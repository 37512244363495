import React from "react";
import { useObjectVal } from "react-firebase-hooks/database";
import { Aula as AulaSchema } from "../../common/atividade";
import firebase from "firebase";
import { getParameterByName, intToRGB, hashCode } from "../../common/util";
import { Container, Typography, Box, Card, Chip } from "@material-ui/core";
import { useMateria } from "../../common/hooks/salas";
import LicaoResolutionForm from "../Licoes/LicaoResolutionForm";

interface AulaProps {
  sala: string;
  id: string;
}

export const Youtube = (props: { id: string }) => {
  return (
    <div
      className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0,
      }}
    >
      <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        src={`https://www.youtube.com/embed/${props.id}`}
        frameBorder="0"
        allowFullScreen
        title="Aula"
      />
    </div>
  );
};

export default function Aula(props: AulaProps) {
  const ref = firebase.database().ref(`aulas/${props.sala}/${props.id}`);
  const [aula] = useObjectVal<AulaSchema>(ref);
  const [materia] = useMateria(aula ? aula.materiaId : "");

  const youtubeId = aula ? getParameterByName("v", aula.link) || "" : "";

  const postado = aula ? new Date(aula.data || 0).toLocaleDateString() : "?";
  const color = intToRGB(hashCode(aula ? aula.materiaId : ""));

  return (
    <>
      <div style={{ background: "#000000" }}>
        <Container maxWidth="md" style={{ padding: 0 }}>
          {youtubeId !== "" && <Youtube id={youtubeId}></Youtube>}
        </Container>
      </div>
      <Container maxWidth="md" style={{ padding: 0 }}>
        <Box p={2}>
          <Chip label={materia} style={{ background: "#" + color }}></Chip>
          <Box mt={1}>
            <Typography variant="h5">{aula?.title}</Typography>
            <Typography variant="body2">Postado em {postado}</Typography>
          </Box>
        </Box>

        <Box p={2}>
          <Card variant="outlined">
            <Box p={2}>
              {aula && !aula.atividadeId && (
                <>O professor não enviou nenhuma atividade para esta aula.</>
              )}
              {aula && aula.atividadeId && (
                <>
                  <Box mb={1}>
                    <Typography variant="h6">Atividade da Aula</Typography>
                  </Box>
                  <LicaoResolutionForm
                    hideSpecial
                    id={aula.atividadeId}
                    sala={props.sala}
                  />
                </>
              )}
            </Box>
          </Card>
        </Box>
      </Container>
    </>
  );
}

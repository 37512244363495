import React, { useState } from "react";
import { Atividade, QuestaoAtividade } from "../../common/atividade";
import firebase from "firebase";
import { useObjectVal } from "react-firebase-hooks/database";
import {
  Box,
  CircularProgress,
  Typography,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@material-ui/core";
import AtividadeIcon from "@material-ui/icons/NotificationImportant";
import MarkdownRender from "../../components/MarkdownLatexRender";
import FolderIcon from "@material-ui/icons/FileCopy";
import { Questao } from "../../components/Questao";

interface LicaoDados {
  id: string;
  sala: string;
  hideSpecial?: boolean;
}

export default function LicaoResolutionForm(props: LicaoDados) {
  const db = firebase.database();

  const [questoes, setQuestoes] = useState<QuestaoAtividade[] | undefined>();

  const ref = db.ref(`licoes/${props.sala}/${props.id}`);
  const [licao, loading] = useObjectVal<Atividade>(ref);

  const ref2 = db.ref(`licoes/${props.sala}/${props.id}/prazo`);
  const [prazoVal] = useObjectVal<string>(ref2);

  const prazo = prazoVal
    ? new Date(prazoVal).toLocaleDateString()
    : "Carregando...";

  if (licao && questoes === undefined) {
    setQuestoes(licao.questoes || []);
  }

  const handleQuestionChange = (index: number) => (q: QuestaoAtividade) => {
    console.log("OK");
    const qs = [...questoes!];

    qs[index] = q;

    setQuestoes(qs);
  };

  if (loading || !licao) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {!props.hideSpecial && (
        <Box mb={1}>
          <Typography variant="h6">Atividade</Typography>
        </Box>
      )}
      <Chip icon={<AtividadeIcon />} label={`Prazo: ${prazo}`} />

      <Box mt={3}>
        <Typography variant="subtitle1">
          <strong>Instruções do Professor</strong>
        </Typography>
        <MarkdownRender source={licao.text}></MarkdownRender>
      </Box>

      {licao.anexos && licao.anexos.length > 0 && (
        <Box mt={3}>
          <Typography variant="subtitle1">
            <strong>Anexos</strong>
          </Typography>
          <List>
            {(licao.anexos || []).map((x, i) => (
              <a href={x.url} target="blank">
                <ListItem key={i}>
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={x.nome} />
                </ListItem>
              </a>
            ))}
            {!licao.anexos && (
              <>Nenhum anexo foi deixado para esta atividade.</>
            )}
          </List>
        </Box>
      )}

      {questoes && questoes.length > 0 && (
        <Box mt={3}>
          <Typography variant="subtitle1">
            <strong>Questões</strong>
          </Typography>
          <List>
            {(questoes || []).map((x, i) => (
              <Questao
                n={i + 1}
                q={x}
                mode="answer"
                onChange={handleQuestionChange(i)}
              />
            ))}
          </List>
        </Box>
      )}
    </>
  );
}

import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
} from "@material-ui/core";

import firebase from "firebase";
import { Anexo } from "../common/atividade";

interface UploadDialogState {
  open: boolean;
  sending: boolean;
}

export default class UploadDialog extends Component<{}, UploadDialogState> {
  fileInput = React.createRef<HTMLInputElement>();
  resolvingPromise?: (file?: Anexo) => void;

  constructor(props: {}) {
    super(props);

    this.state = {
      open: false,
      sending: false,
    };
  }

  public uploadFile = () => {
    this.handleClickOpen();

    return new Promise<Anexo | undefined>((resolve) => {
      this.resolvingPromise = resolve;
    });
  };

  handleClickOpen = () => {
    this.setState({ open: true, sending: false });
  };

  handleClose = () => {
    if (this.resolvingPromise) {
      this.resolvingPromise();
      this.resolvingPromise = undefined;
    }

    this.setState({ open: false });
  };

  handleUpload = async () => {
    this.setState({ sending: true });

    if (!this.resolvingPromise) {
      this.setState({ open: false });
      return;
    }

    const auth = firebase.auth();

    if (
      auth.currentUser &&
      this.fileInput.current &&
      this.fileInput.current.files &&
      this.fileInput.current.files[0]
    ) {
      const storage = firebase.storage();

      const file = this.fileInput.current.files[0];
      const buffer = await file.slice();

      const uploaded = await storage
        .ref("anexos_atividades")
        .child(auth.currentUser.uid)
        .child("S-" + Date.now())
        .child(file.name)
        .put(buffer, {
          contentType: file.type,
        });

      const url = (await uploaded.ref.getDownloadURL()) as string;

      this.resolvingPromise({
        nome: file.name,
        tipo: file.type,
        url: url,
      });
      this.resolvingPromise = undefined;
      this.setState({ open: false });
    } else {
      this.handleClose();
    }

    this.setState({ sending: false });
  };

  render() {
    return (
      <Dialog open={this.state.open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Adicionar PDF ou Foto</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Adicione um arquivo. Ele será linkado ao seu ID único. Em caso de
            envio de arquivos irregulares por parte de alunos, a direção pode
            ser contatada para devidas punições.
          </DialogContentText>
          <input type="file" ref={this.fileInput} />
        </DialogContent>
        <DialogActions>
          {!this.state.sending && (
            <>
              <Button onClick={this.handleClose} color="primary">
                Cancelar
              </Button>
              <Button onClick={this.handleUpload} color="primary">
                Enviar
              </Button>
            </>
          )}
          {this.state.sending && (
            <>
              <CircularProgress />
            </>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

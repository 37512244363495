import React from "react";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  Button,
  Card,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { QuestaoAtividade } from "../common/atividade";
import MarkdownRender from "./MarkdownLatexRender";

export const Questao = (props: {
  q: QuestaoAtividade;
  n: number;
  mode: "answer" | "create";
  onChange: (q: QuestaoAtividade) => void;
}) => {
  const handleChange = (prop: keyof QuestaoAtividade) => (
    event: React.ChangeEvent<any>
  ) => {
    let newVal = event.target.value;

    if (prop === "alternativaCorreta" || prop === "respostaAlunoAlternativa") {
      newVal = parseInt(newVal);
    }

    const qs = { ...props.q, [prop]: newVal };

    console.log(prop, qs);

    props.onChange(qs);
  };

  const handleAlternativaChange = (i: number) => (
    event: React.ChangeEvent<any>
  ) => {
    const alternativas = [...props.q.alternativas];

    alternativas[i] = event.target.value;

    const qs = { ...props.q, alternativas: alternativas };

    props.onChange(qs);
  };

  const pushAlternativa = () => {
    const alternativas = [...props.q.alternativas, ""];

    const qs = { ...props.q, alternativas: alternativas };

    props.onChange(qs);
  };

  return (
    <Box mt={2}>
      <Card variant="outlined" elevation={4}>
        <Box p={2}>
          <Typography>
            <strong>Questão {props.n}</strong>
          </Typography>
          {props.mode === "create" && (
            <Box mt={2}>
              <TextField
                id="outlined-basic"
                label="Enunciado/Prompt"
                variant="outlined"
                value={props.q.prompt}
                onChange={handleChange("prompt")}
                fullWidth
              />
            </Box>
          )}
          <Typography>
            {props.mode === "answer" && (
              <MarkdownRender source={props.q.prompt}></MarkdownRender>
            )}
          </Typography>
          {props.mode === "create" && (
            <Box mt={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Tipo de Questão</FormLabel>
                <RadioGroup
                  aria-label="Tipo"
                  name="type"
                  value={props.q.type}
                  onChange={handleChange("type")}
                >
                  <Box display="flex" flexDirection="row">
                    <FormControlLabel
                      value="dissertativa"
                      control={<Radio color="primary" />}
                      color="primary"
                      label="Dissertativa"
                    />
                    <FormControlLabel
                      value="objetiva"
                      color="primary"
                      control={<Radio color="primary" />}
                      label="Objetiva"
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </Box>
          )}
          {props.q.type === "dissertativa" && props.mode === "answer" && (
            <Box mt={2}>
              <TextField
                id="outlined-basic"
                label="Resposta"
                variant="outlined"
                value={props.q.respostaAlunoValue || ""}
                onChange={handleChange("respostaAlunoValue")}
                fullWidth
              />
            </Box>
          )}
          {props.q.type === "objetiva" && (
            <Box mt={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Alternativas:</FormLabel>
                <RadioGroup
                  aria-label="Tipo"
                  name="type"
                  value={
                    props.mode === "create"
                      ? props.q.alternativaCorreta
                      : props.q.respostaAlunoAlternativa !== undefined
                      ? props.q.respostaAlunoAlternativa
                      : -1
                  }
                  onChange={handleChange(
                    props.mode === "create"
                      ? "alternativaCorreta"
                      : "respostaAlunoAlternativa"
                  )}
                >
                  {props.q.alternativas.map((x, i) => (
                    <Box
                      display="flex"
                      flexDirection="row"
                      mt={props.mode === "create" ? 2 : 0}
                    >
                      <FormControlLabel
                        value={i}
                        control={<Radio color="primary" />}
                        color="primary"
                        label={props.mode === "answer" ? x : ""}
                      />

                      {props.mode === "create" && (
                        <Box flex="1">
                          <TextField
                            id="outlined-basic"
                            label="Texto da Alternativa"
                            variant="outlined"
                            value={x}
                            onChange={handleAlternativaChange(i)}
                            fullWidth
                            size="small"
                          />
                        </Box>
                      )}
                    </Box>
                  ))}
                  {props.mode === "create" && (
                    <Box mt={2}>
                      <Button color="primary" onClick={pushAlternativa}>
                        Adicionar Alternativa
                      </Button>
                    </Box>
                  )}
                </RadioGroup>
              </FormControl>
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
};

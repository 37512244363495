import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import firebase from "firebase";

export default function LoginProf() {
  const [state, setState] = React.useState<{
    pass: string;
    logando: boolean;
    error: boolean;
  }>({
    pass: "",
    logando: false,
    error: false,
  });

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value?: string }>
  ) => {
    setState({
      ...state,
      pass: event.target.value || "",
    });
  };

  const handleClick = async () => {
    setState({
      ...state,
      logando: true,
    });

    const success = await firebase
      .auth()
      .signInWithEmailAndPassword("professor@acetto.kaykyb.com", state.pass);

    setState({
      ...state,
      logando: false,
      error: !success,
    });
  };

  return (
    <Box display="flex" justifyContent="center">
      <Box maxWidth="240px" width="100%">
        <Typography align="center">Insira sua senha:</Typography>
        <Box mt={2}>
          <TextField
            label="Senha"
            type="password"
            variant="outlined"
            fullWidth
            onChange={handleChange}
            value={state.pass}
          />
        </Box>

        {state.error && (
          <Box mt={2} width="100%">
            <Alert severity="error">Senha incorreta.</Alert>
          </Box>
        )}

        {!state.logando ? (
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={handleClick}
            >
              login
            </Button>
          </Box>
        ) : (
          <Box mt={2} width="100%" textAlign="center">
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
}

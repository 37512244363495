import React from "react";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Aula } from "../../common/atividade";
import { useMaterias } from "../../common/hooks/salas";

interface CriarAulaFormProps {
  values: Aula;
  onChange: (key: keyof Aula, value: string) => void;
}

export default function CriarAulaForm(
  props: React.PropsWithChildren<CriarAulaFormProps>
) {
  const [materias] = useMaterias();
  const materiasIds = Object.keys(materias || {});

  const handleChange = (prop: keyof Aula) => (
    event: React.ChangeEvent<any>
  ) => {
    props.onChange(prop, event.target.value);
  };

  return (
    <Box>
      <Typography variant="h6">Dados da Aula</Typography>

      <Box mt={2}>
        <TextField
          id="outlined-basic"
          label="Link do YouTube"
          variant="outlined"
          value={props.values.link}
          onChange={handleChange("link")}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <TextField
          id="outlined-basic"
          label="Título da Aula"
          variant="outlined"
          value={props.values.title}
          onChange={handleChange("title")}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="demo-simple-select-outlined-label">
            Matéria
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="Matéria"
            value={props.values.materiaId}
            onChange={handleChange("materiaId")}
            fullWidth
          >
            {materiasIds.map((x, i) => (
              <MenuItem value={x}>{materias ? materias[x] : ""}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}

import React from "react";
import { Card, Box, Tabs, Tab, Container, Divider } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/PersonOutlined";
import ClassIcon from "@material-ui/icons/ClassOutlined";
import { Redirect } from "react-router-dom";

import { TabPanel } from "../../components/TabPanel";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase";

// Inset views
import LoginAluno from "./LoginAluno";
import LoginProf from "./LoginProf";

export default function Login() {
  const [user] = useAuthState(firebase.auth());
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container>
      {user && (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      )}
      <Card variant="outlined">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon label tabs example"
        >
          <Tab icon={<PersonIcon />} label="aluno" />
          <Tab icon={<ClassIcon />} label="professor" />
        </Tabs>
        <Divider />
        <TabPanel value={value} index={0}>
          <Box p={3}>
            <LoginAluno />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box p={3}>
            <LoginProf />
          </Box>
        </TabPanel>
      </Card>
    </Container>
  );
}

import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import CriarAulaForm from "./CriarAulaForm";
import {
  Box,
  FormControlLabel,
  Checkbox,
  Backdrop,
  CircularProgress,
  Container,
} from "@material-ui/core";
import CriarAtividadeForm from "./CriarAtividadeForm";
import { Atividade, Aula } from "../../common/atividade";

import firebase from "firebase";

interface CriarAulaDialogState {
  aulaDados: Aula;
  atividadeDados: Atividade;
  addAtividade: boolean;
  uploading: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "fixed",
    },
    appBarSpacer: {
      position: "relative",
      zIndex: -1,
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CriarAulaDialog(
  props: React.PropsWithChildren<{
    open: boolean;
    sala: string;
    setOpen: (state: boolean) => void;
  }>
) {
  const classes = useStyles();

  const [values, setValues] = React.useState<CriarAulaDialogState>({
    aulaDados: {
      link: "",
      title: "",
      materiaId: "",
    },
    atividadeDados: {
      title: "",
      text: "",
      materiaId: "",
      anexos: [],
      prazo: Date.now(),
      questoes: [],
    },
    addAtividade: false,
    uploading: false,
  });

  const handleCriarAulaFormChange = (key: keyof Aula, value: string) => {
    setValues({
      ...values,
      aulaDados: {
        ...values.aulaDados,
        [key]: value,
      },
    });
  };

  const handleCriarAtivFormChange = (key: keyof Atividade, value: any) => {
    setValues({
      ...values,
      atividadeDados: {
        ...values.atividadeDados,
        [key]: value,
      },
    });
  };

  const handleAddAtividade = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setValues({
      ...values,
      addAtividade: checked,
    });
  };

  const handleClose = () => {
    setValues({
      aulaDados: {
        link: "",
        title: "",
        materiaId: "",
      },
      atividadeDados: {
        title: "",
        text: "",
        materiaId: "",
        anexos: [],
        prazo: Date.now(),
        questoes: [],
      },
      addAtividade: false,
      uploading: false,
    });
    props.setOpen(false);
  };

  const handleSave = async () => {
    setValues({
      ...values,
      uploading: true,
    });

    const db = firebase.database();
    const aula = values.aulaDados;

    const ativi = values.atividadeDados;
    ativi.title = aula.title;
    ativi.materiaId = aula.materiaId;

    if (values.addAtividade) {
      const pushed = await db
        .ref(`licoes/${props.sala}`)
        .push(values.atividadeDados);

      aula.atividadeId = pushed.key || undefined;
    }

    aula.data = Date.now();

    await db.ref(`aulas/${props.sala}`).push(aula);

    setValues({
      ...values,
      uploading: false,
    });

    handleClose();
  };

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Backdrop open={values.uploading} style={{ zIndex: 99999999 }}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <AppBar className={classes.appBar}>
        <Container maxWidth="md" style={{ padding: 0, margin: "0 auto" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Adicionar Aula
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSave}>
              Salvar
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
      <AppBar className={classes.appBarSpacer}>
        <Toolbar></Toolbar>
      </AppBar>
      <Container maxWidth="sm" style={{ padding: 0, margin: "0 auto" }}>
        <Box p={3} pb={6}>
          <CriarAulaForm
            values={values.aulaDados}
            onChange={handleCriarAulaFormChange}
          />
          <Box mt={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.addAtividade}
                  onChange={handleAddAtividade}
                  color="primary"
                  name="addActivity"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              }
              label="Adicionar Atividade"
            />
          </Box>
          {values.addAtividade && (
            <Box mt={2}>
              <CriarAtividadeForm
                hideSpecial
                values={values.atividadeDados}
                onChange={handleCriarAtivFormChange}
              ></CriarAtividadeForm>
            </Box>
          )}
        </Box>
      </Container>
    </Dialog>
  );
}

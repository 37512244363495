import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  makeStyles,
  Theme,
  createStyles,
  Container,
} from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/ArrowBack";
import { Link, useHistory } from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase";
import { useNomeFromEmail } from "../common/hooks/alunos";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: "100%",
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(3),
    },
    title: {
      flexGrow: 1,
    },
  })
);

function Navbar(props: React.PropsWithChildren<{}>) {
  const FIRE_AUTH = firebase.auth();

  const classes = useStyles();

  // Hooks
  const [user] = useAuthState(FIRE_AUTH);
  const [nome] = useNomeFromEmail(user ? user.email || "" : "");
  const history = useHistory();

  const handleBack = () => {
    history.push("/");
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Container maxWidth="md" style={{ padding: 0 }}>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={handleBack}
            >
              <BackIcon />
            </IconButton>

            <Typography variant="h6" noWrap className={classes.title}>
              <Link to="/">
                {user && <>Olá, </>}
                {user ? (
                  <span style={{ textTransform: "capitalize" }}>
                    {nome.split(" ")[0].toLowerCase()}
                  </span>
                ) : (
                  "Acetto"
                )}
              </Link>
            </Typography>

            {user ? (
              <Button color="inherit" onClick={() => FIRE_AUTH.signOut()}>
                SAIR
              </Button>
            ) : (
              <Link to="/login">
                <Button color="inherit">entrar</Button>
              </Link>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
}

export default Navbar;

import React from "react";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Card,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Link,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Atividade, QuestaoAtividade } from "../../common/atividade";
import FolderIcon from "@material-ui/icons/FileCopy";

import UploadDialog from "../../components/UploadDialog";
import { useMaterias } from "../../common/hooks/salas";
import MarkdownRender from "../../components/MarkdownLatexRender";
import { Questao } from "../../components/Questao";

interface CriarAtividadeFormProps {
  hideSpecial?: boolean;
  values: Atividade;
  onChange: (key: keyof Atividade, value: any) => void;
}

export default function CriarAtividadeForm(
  props: React.PropsWithChildren<CriarAtividadeFormProps>
) {
  const uploadDialogRef = React.createRef<UploadDialog>();

  const [materias] = useMaterias();
  const materiasIds = Object.keys(materias || {});

  const handleChange = (prop: keyof Atividade) => (
    event: React.ChangeEvent<any>
  ) => {
    props.onChange(prop, event.target.value);
  };

  const handleQuestionChange = (index: number) => (q: QuestaoAtividade) => {
    const qs = [...props.values.questoes];

    qs[index] = q;

    props.onChange("questoes", qs);
  };

  const pushQ = () => {
    const qs = [...props.values.questoes];

    qs.push({
      prompt: "",
      type: "dissertativa",
      alternativas: [],
      alternativaCorreta: 0,
      ignore: false,
    });

    props.onChange("questoes", qs);
  };

  const pushAnexo = async () => {
    if (!uploadDialogRef.current) return;

    const anexo = await uploadDialogRef.current.uploadFile();

    if (!anexo) return;

    const qs = [...props.values.anexos];

    qs.push(anexo);

    props.onChange("anexos", qs);
  };

  const handleDateChange = (date: MaterialUiPickersDate) => {
    props.onChange("prazo", date?.getTime());
  };

  return (
    <Box mt={2}>
      <UploadDialog ref={uploadDialogRef} />
      <Typography variant="h6">Dados da Atividade</Typography>
      {!props.hideSpecial && (
        <>
          <Box mt={2}>
            <TextField
              id="outlined-basic"
              label="Título da Atividade"
              variant="outlined"
              value={props.values.title}
              onChange={handleChange("title")}
              fullWidth
            />
          </Box>

          <Box mt={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                Matéria
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Matéria"
                value={props.values.materiaId}
                onChange={handleChange("materiaId")}
                fullWidth
              >
                {materiasIds.map((x, i) => (
                  <MenuItem value={x}>{materias ? materias[x] : ""}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </>
      )}
      <Box mt={3}>
        <Typography>
          <strong>Prazo</strong>
        </Typography>
        <Box mt={2}>
          <DatePicker
            variant="dialog"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            fullWidth
            value={new Date(props.values.prazo)}
            onChange={handleDateChange}
          />
        </Box>
      </Box>

      <Box mt={3}>
        <Typography>
          <strong>Conteúdo</strong>
        </Typography>
      </Box>
      <Box mt={1}>
        <Alert severity="info">
          Você pode usar Markdown e LaTeX para estilizar o conteúdo e adicionar
          fórmulas matemáticas e químicas.
          <Box display="flex" flexDirection="row" alignItems="center">
            <code>$f(x) = x^2$</code>
            <span style={{ margin: 12 }}> se transforma em </span>
            <MarkdownRender source="$f(x) = x^2$" />
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <code>**Olá, Mundo**</code>
            <span style={{ margin: 12 }}> se transforma em </span>
            <MarkdownRender source="-**Olá, Mundo**-" />
          </Box>
          <Link
            target="blank"
            href="https://docs.pipz.com/central-de-ajuda/learning-center/guia-basico-de-markdown#open"
          >
            Acesse o Guia básico de Markdown
          </Link>
        </Alert>
      </Box>
      <Box mt={2}>
        <TextField
          label="Instruções"
          variant="outlined"
          value={props.values.text}
          onChange={handleChange("text")}
          multiline
          fullWidth
        />
      </Box>
      {props.values.text && (
        <Box mt={1}>
          <Card variant="outlined">
            <Box p={2} pb={0}>
              <strong>Prévia:</strong>
              <MarkdownRender source={props.values.text} />
            </Box>
          </Card>
        </Box>
      )}

      <Box mt={3}>
        <strong>Anexos ({props.values.anexos.length})</strong>
      </Box>

      <List>
        {props.values.anexos.map((x, i) => (
          <a href={x.url} target="blank">
            <ListItem key={i}>
              <ListItemAvatar>
                <Avatar>
                  <FolderIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={x.nome} />
            </ListItem>
          </a>
        ))}
      </List>

      <Box mt={2}>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          size="large"
          onClick={pushAnexo}
        >
          Adicionar Anexo
        </Button>
      </Box>

      <Box mt={3}>
        <strong>Questões ({props.values.questoes.length})</strong>
      </Box>

      {props.values.questoes.map((x, i) => (
        <Questao
          q={x}
          n={i + 1}
          key={i}
          mode={"create"}
          onChange={handleQuestionChange(i)}
        ></Questao>
      ))}

      <Box mt={2}>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          size="large"
          onClick={pushQ}
        >
          Adicionar Questão
        </Button>
      </Box>
    </Box>
  );
}
